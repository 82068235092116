//================================================
// Authentication
//================================================
export const register = (state) => state.register;
export const sendValidationMail = (state) => state.sendValidationMail;
export const getValidationMailStatus = (state) => state.getValidationMailStatus;

//================================================
// Reset Password
//================================================
export const resetPasswordWithOld = (state) => state.resetPasswordWithOld;
export const resetPasswordWithToken = (state) => state.resetPasswordWithToken;
export const sendResetPasswordTokenEmail = (state) =>
  state.sendResetPasswordTokenEmail;

//================================================
// Widgets
//================================================
export const getTrendingTickersRes = (state) => state.getTrendingTickers;
export const getTrendingIndicesRes = (state) => state.getTrendingIndices;
export const getAllTrendingIndicesRes = (state) => state.getAllTrendingIndices;

//================================================
// Feed
//================================================
export const getFeedRes = (state) => state.getFeed;
export const countNewFeedRes = (state) => state.countNewFeed;

//================================================
// Industry
//================================================
export const getIndustriesRes = (state) => state.getIndustries;
export const followIndustryToggleRes = (state) => state.followIndustryToggle;

//================================================
// Messages
//================================================
export const getMessageNotificationsAllRes = (state) =>
  state.getMessageNotificationsAll;
export const markAsReadMessageNotificationsAllRes = (state) =>
  state.markAsReadMessageNotificationsAll;

//================================================
// News
//================================================
export const getNewsRes = (state) => state.getNews;
export const getCryptoNewsRes = (state) => state.getCryptoNews;
export const getNewsDetailRes = (state) => state.getNewsDetail;

//================================================
// Tickers
//================================================
export const getCryptoTickerStockValue = (state) =>
  state.getCryptoTickerStockValue;
export const getTickerStockValue = (state) => state.getTickerStockValue;

//================================================
// Profile
//================================================
export const getProfileByIdRes = (state) => state.getProfileById;
export const getSymbolByIdRes = (state) => state.getSymbolById;
export const createProfileRes = (state) => state.createProfile;
export const updateProfileRes = (state) => state.updateProfile;
export const uploadProfileAvatarRes = (state) => state.uploadProfileAvatar;
export const uploadProfileHeaderRes = (state) => state.uploadProfileHeader;
export const validateProfileProfilenameRes = (state) =>
  state.validateProfileProfilename;
export const getProfileIdFromNameRes = (state) => state.getProfileIdFromName;
export const publicValidateProfileProfilenameRes = (state) =>
  state.publicValidateProfileProfilename;
export const publicValidateUserEmailRes = (state) =>
  state.publicValidateUserEmail;
export const publicGetuserFromProfileNameRes = (state) =>
  state.publicGetUserFromProfileName;
export const followProfileRes = (state) => state.followProfile;
export const followStockChangeRes = (state) => state.followStockChange;
export const followedIdustriesRes = (state) => state.followedIdustries;
export const rateProfileRes = (state) => state.rateProfile;
export const getProfileFollowedRes = (state) => state.getProfileFollowed;
export const getProfileFollowersRes = (state) => state.getProfileFollowers;
export const getCompanySymbolsRes = (state) => state.getCompanySymbols;
export const getMultipleCompanySymbolsRes = (state) =>
  state.getMultipleCompanySymbols;
export const getCryptoSymbolsRes = (state) => state.getCryptoSymbols;
export const getPrimaryTickerFundamentalsRes = (state) =>
  state.getPrimaryTickerFundamentals;
export const getTickerFundamentalsRes = (state) => state.getTickerFundamentals;
export const seenWTPModalRes = (state) => state.seenWTPModal;
export const deleteProfileRes = (state) => state.deleteProfile;
export const getCompanyPortfoliosRes = (state) => state.getCompanyPortfolios;
export const getCompanyProfileCommentsRes = (state) =>
  state.getCompanyProfileComments;
export const getProfileFollowedCountPerTypeRes = (state) =>
  state.getProfileFollowedCountPerType;

//================================================
// Users
//================================================
export const updateUsersMeRes = (state) => state.updateUsersMe;
export const patchUserBirthdateRes = (state) => state.patchUserBirthdate;
export const deleteUserRes = (state) => state.deleteUser;

//================================================
// Search
//================================================
export const searchRes = (state) => state.search;

//================================================
// Portfolio
//================================================
export const createPortfolioRes = (state) => state.createPortfolio;
export const getPortfolioTopGainersRes = (state) =>
  state.getPortfolioTopGainers;
export const getPortfolioTopGainersFollowedRes = (state) =>
  state.getPortfolioTopGainersFollowed;
export const getPortfolioMonthlyTopGainersRes = (state) =>
  state.getPortfolioMonthlyTopGainers;
export const portfolioSearchRes = (state) => state.portfoliosSearch;
export const getPortfolioComparisonGraphInfoRes = (state) =>
  state.getPortfolioComparisonGraphInfo;
export const orderPortfoliosRes = (state) => state.orderPortfolios;
export const getPortfolioByIdRes = (state) => state.getPortfolioById;
export const removePortfolioRes = (state) => state.removePortfolio;
export const likePortfolioRes = (state) => state.likePortfolio;
export const toggleCompleteViewRes = (state) => state.toggleCompleteView;
export const updatePortfolioRes = (state) => state.updatePortfolio;
export const getPortfolioGraphRes = (state) => state.getPortfolioGraph;
export const getMyPortfoliosRes = (state) => state.getMyPortfolios;
export const getProfilePortfoliosRes = (state) => state.getProfilePortfolios;
export const createOperationRes = (state) => state.createOperation;
export const createCashOperationRes = (state) => state.createCashOperation;
export const addPortfolioItemRes = (state) => state.addPortfolioItem;
export const removePortfolioItemRes = (state) => state.removePortfolioItem;
export const removePortfolioCashItemRes = (state) =>
  state.removePortfolioCashItem;
export const getTickerStockValueRes = (state) => state.getTickerStockValue;
export const getSimilarTickersRes = (state) => state.getSimilarTickers;
export const getFavoritePortfolioRes = (state) => state.getFavoritePortfolio;
export const getMyFavoritePortfoliosRes = (state) =>
  state.getMyFavoritePortfolios;
export const getTickerStockRangeValueRes = (state) =>
  state.getTickerStockRangeValue;
export const getTickerStockRangeWithOverallRes = (state) =>
  state.getTickerStockRangeWithOverall;
export const getMultipleTickerStockValueRes = (state) =>
  state.getMultipleTickerStockValue;
export const getMultipleTickerStockValueWithOverallRes = (state) =>
  state.getMultipleTickerStockValueWithOverall;
export const getTeamPortfolioProfilesRes = (state) =>
  state.getTeamPortfolioProfiles;
export const updateTeamPortfolioProfileRes = (state) =>
  state.updateTeamPortfolioProfile;
export const deleteTeamPortfolioProfileRes = (state) =>
  state.deleteTeamPortfolioProfile;
export const createTeamPortfolioProfileRes = (state) =>
  state.createTeamPortfolioProfile;
export const favoritePortfolioRes = (state) => state.favoritePortfolio;
export const getPortfolioCommentsRes = (state) => state.getPortfolioComments;
export const getPortfolioItemsGainForInterval = (state) =>
  state.getPortfolioItemsGainForInterval;

//================================================
// Post
//================================================
export const getPostByIdRes = (state) => state.getPostById;
export const getPostsRes = (state) => state.getPosts;
export const getPostRepliesRes = (state) => state.getPostReplies;
export const createPostRes = (state) => state.createPost;
export const updatePostRes = (state) => state.updatePost;
export const uploadPostImageRes = (state) => state.uploadPostImage;
export const uploadPortfolioImageRes = (state) => state.uploadPortfolioImage;
export const likePostRes = (state) => state.likePost;
export const favoritePostRes = (state) => state.favoritePost;
export const getMyFavoritesPostRes = (state) => state.getMyFavoritesPost;
export const getProfilePostsRes = (state) => state.getProfilePosts;

//================================================
// Notifications
//================================================
export const notificationsRes = (state) => state.notifications;
export const handleReadAllNotificationRes = (state) =>
  state.handleReadAllNotification;
export const handleReadMultipleNotifications = (state) =>
  state.handleReadMultipleNotifications;
export const handleReadNotificationRes = (state) =>
  state.handleReadNotification;
export const removeNotificationRes = (state) => state.removeNotification;
export const notificationsCountRes = (state) => state.notificationsCount;

//================================================
// Onboarding
//================================================
export const tutorialNextStepRes = (state) => state.tutorialNextStep;
export const completeTutorialRes = (state) => state.completeTutorial;
export const tipsNextStepRes = (state) => state.tipsNextStep;
export const completeTipsRes = (state) => state.completeTips;
export const userOnboardingQuestions = (state) =>
  state.getUserOnboardingQuestions;
export const userOnboardingAnswers = (state) =>
  state.getUserOnboardingAnswersByProfileId;

//================================================
// Recommendations
//================================================
export const getRecommendedProfilesRes = (state) =>
  state.getRecommendedProfiles;
export const getRecommendedCompaniesRes = (state) =>
  state.getRecommendedCompanies;
export const getMutualFollowsRecommendationRes = (state) =>
  state.getMutualFollowsRecommendation;
export const getMutualIndustriesRecommendationRes = (state) =>
  state.getMutualIndustriesRecommendation;
export const getSimilarCompaniesRecommendationRes = (state) =>
  state.getSimilarCompaniesRecommendation;
export const getCompanyFollowersRecommendationRes = (state) =>
  state.getCompanyFollowersRecommendation;

export const getTopInvestorsRes = (state) => state.getTopInvestors;
//================================================
// Languages
//================================================
export const getNewsSuscribedLanguagesRes = (state) =>
  state.getNewsSuscribedLanguages;

//================================================
// Stocks
//================================================
export const searchStocksRes = (state) => state.searchStocks;

//================================================
// Leagues
//================================================
export const createAdminLeagueRes = (state) => state.createAdminLeague;
export const searchLeaguesRes = (state) => state.searchLeagues;
export const getPendingProfileInvitationsRes = (state) =>
  state.getPendingProfileInvitations;
export const answerLeagueInvitationRes = (state) =>
  state.answerLeagueInvitation;
