
export default {
  name: 'UILeagueAllowedExchangeTags',
  components: {},
  props: {
    allowedExchanges: {
      type: Array,
      required: true,
    },
    cropLength: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    sanitizedList() {
      return this.allowedExchanges.filter((e) => e.length > 0);
    },
  },
};
