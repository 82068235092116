export const showCreatePostModalRes = (state) => state.showCreatePostModal;
export const createPortfolioModal = (state) => state.createPortfolioModal;
export const showLegalDisclaimerModal = (state) =>
  state.showLegalDisclaimerModal;
export const showImageSliderModalRes = (state) => state.showImageSliderModal;
export const showSelectProfileModalRes = (state) =>
  state.showSelectProfileModal;
export const currentMentionCard = (state) => state.currentMentionCard;
export const isMentionHovered = (state) => state.mentionHovered;
export const isMentionCardHovered = (state) => state.mentionCardHovered;
export const passwordResetModal = (state) => state.passwordResetModal;
export const createPortfolioOperationModal = (state) =>
  state.createPortfolioOperationModal;
export const helpCenterObjectRes = (state) => state.helpCenterObjectRes;
export const showProfileCardRes = (state) => state.showProfileCardRes;
export const portfolioEmptyStateModal = (state) =>
  state.portfolioEmptyStateModal;
export const giphyModal = (state) => state.giphyModal;
export const compareTickersModal = (state) => state.compareTickersModal;
export const compareTickersModalSelectedTicker = (state) =>
  state.compareTickersModal.selectedTicker;
export const itemsInComparison = (state) =>
  state.compareTickersModal.numberOfItemsInComparison;
export const comparePortfoliosModal = (state) => state.comparePortfoliosModal;
export const portfoliosInComparison = (state) =>
  state.comparePortfoliosModal.numberOfItemsInComparison;
export const manageTeamPortfolioModal = (state) =>
  state.manageTeamPortfolioModal;
export const createLeagueModal = (state) => state.createLeagueModal;
export const showPortfolioAssetOperationModalFooter = (state) =>
  state.showPortfolioAssetOperationModalFooter;
export const hasClosedEmailConfirmationBanner = (state) =>
  state.hasClosedEmailConfirmationBanner;
export const showNoDataAtDateError = (state) => state.showNoDataAtDateError;
