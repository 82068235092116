/**
 * Returns translated company type based on a company type that is passed in
 * @param companyType company type to translate
 * @returns translated company type
 */
export function getCompanyTagText(companyType: string): string {
  switch (companyType) {
    case 'Stock':
      return window.$nuxt.$t('company') as string;
    case 'Index':
      return window.$nuxt.$t('index') as string;
    case 'Cryptocurrency':
      return window.$nuxt.$t('Cryptocurrency') as string;
    case 'ETF':
      return window.$nuxt.$t('etf_tag') as string;
    default:
      return '';
  }
}

export function getSymbolTagText(symbolType: string): string {
  switch (symbolType) {
    case 'Stock':
      return window.$nuxt.$t('stock_symbol_type_tag') as string;
    case 'Index':
      return window.$nuxt.$t('index') as string;
    case 'Cryptocurrency':
      return window.$nuxt.$t('Cryptocurrency') as string;
    case 'ETF':
      return window.$nuxt.$t('etf_tag') as string;
    default:
      return '';
  }
}
