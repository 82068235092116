
import ReportPostModal from '@/components/ui/modal/ReportPostModal';
import { mapGetters, mapActions } from 'vuex';
import MoreIcon from '~/components/ui/icons/MoreIcon.vue';

export default {
  name: 'PostMore',
  components: {
    ReportPostModal,
    MoreIcon,
  },
  props: {
    postId: {
      type: String,
      default: '',
    },
    isFavorite: {
      type: Boolean,
      required: true,
    },
    postAuthorId: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters('authentication', ['profileId']),
  },
  methods: {
    ...mapActions('api', ['reportPost']),

    showReportPostModal() {
      this.show = true;
    },
    closeReportPostModal() {
      this.show = false;
    },
    sendReport(content) {
      this.reportPost({ content: content, postId: this.postId });
      this.closeReportPostModal();
    },
  },
};
