import { MutationsCreator } from '@/store/utils';

export default MutationsCreator([
  //================================================
  // Authentication
  //================================================
  {
    name: 'REGISTER',
    state: 'register',
  },
  {
    name: 'SEND_VALIDATION_MAIL',
    state: 'sendValidationMail',
  },
  {
    name: 'GET_VALIDATION_MAIL_STATUS',
    state: 'getValidationMailStatus',
  },

  //================================================
  // Reset Password
  //================================================
  {
    name: 'RESET_PASSWORD_WITH_OLD',
    state: 'resetPasswordWithOld',
  },
  {
    name: 'RESET_PASSWORD_WITH_TOKEN',
    state: 'resetPasswordWithToken',
  },
  {
    name: 'SEND_RESET_PASSWORD_TOKEN_EMAIL',
    state: 'sendResetPasswordTokenEmail',
  },

  //================================================
  // Feed
  //================================================
  {
    name: 'GET_FEED',
    state: 'getFeed',
  },
  {
    name: 'COUNT_NEW_FEED',
    state: 'countNewFeed',
  },

  //================================================
  // Industry
  //================================================
  {
    name: 'GET_INDUSTRY',
    state: 'getIndustries',
  },
  {
    name: 'FOLLOW_INDUSTRY_TOGGLE',
    state: 'followIndustryToggle',
  },

  //================================================
  // Messages
  //================================================
  {
    name: 'GET_MESSAGE_NOTIFICATIONS_ALL',
    state: 'getMessageNotificationsAll',
  },
  {
    name: 'MARK_AS_READ_MESSAGE_NOTIFICATIONS_ALL',
    state: 'markAsReadMessageNotificationsAll',
  },
  {
    name: 'REMOVE_NOTIFICATION',
    state: 'removeNotification',
  },
  {
    name: 'GET_PROFILE_MESSAGE_PRIVACY_SETTINGS',
    mutation: 'getProfilesMessagePrivacySettings',
  },
  {
    name: 'SET_PROFILE_MESSAGE_PRIVACY_SETTINGS',
    mutation: 'setProfilesMessagePrivacySettings',
  },
  {
    name: 'GET_USERS_FOR_MESSAGE_BUTTON',
    mutation: 'getUsersForMessageButton',
  },
  {
    name: 'GET_INITIAL_USERS',
    mutation: 'getInitialUsers',
  },

  //================================================
  // News
  //================================================
  {
    name: 'GET_NEWS',
    state: 'getNews',
  },
  {
    name: 'GET_CRYPTO_NEWS',
    state: 'getCryptoNews',
  },
  {
    name: 'GET_NEWS_HEADLINES',
    state: 'getNewsHeadlines',
  },
  {
    name: 'GET_COMPANY_HEADLINES',
    state: 'getCompanyHeadlines',
  },
  {
    name: 'GET_MORE_COMPANY_HEADLINES',
    state: 'getMoreCompanyHeadlines',
  },

  //================================================
  // Widgets
  //================================================

  {
    name: 'GET_TRENDING_TICKERS',
    state: 'getTrendingTickers',
  },

  {
    name: 'GET_TRENDING_INDICES',
    state: 'getTrendingIndices',
  },
  {
    name: 'GET_ALL_TRENDING_INDICES',
    state: 'getAllTrendingIndices',
  },
  {
    name: 'GET_TRENDING_CRYPTOS',
    mutation: 'getTrendingCryptos',
  },
  {
    name: 'GET_POPULAR_CRYPTOS',
    mutation: 'getPopularCryptos',
  },
  {
    name: 'GET_SIMILAR_CRYPTOS',
    mutation: 'getSimilarCryptos',
  },

  //================================================
  // Profile
  //================================================
  {
    name: 'GET_COMPANY_SYMBOLS',
    state: 'getCompanySymbols',
  },
  {
    name: 'GET_MULTIPLE_COMPANY_SYMBOLS',
    state: 'getMultipleCompanySymbols',
  },
  {
    name: 'GET_CRYPTO_SYMBOLS',
    state: 'getCryptoSymbols',
  },
  {
    name: 'GET_PRIMARY_TICKER_FUNDAMENTALS',
    state: 'getPrimaryTickerFundamentals',
  },
  {
    name: 'GET_TICKER_FUNDAMENTALS',
    state: 'getTickerFundamentals',
  },
  {
    name: 'GET_PROFILE_BY_ID',
    state: 'getProfileById',
  },
  {
    name: 'GET_SYMBOL_BY_ID',
    state: 'getSymbolProfileById',
  },
  {
    name: 'CREATE_PROFILE',
    state: 'createProfile',
  },
  {
    name: 'UPDATE_PROFILE',
    state: 'updateProfile',
  },
  {
    name: 'UPLOAD_PROFILE_AVATAR',
    state: 'uploadProfileAvatar',
  },
  {
    name: 'UPLOAD_PROFILE_HEADER',
    state: 'uploadProfileHeader',
  },
  {
    name: 'VALIDATE_PROFILE_USERNAME',
    state: 'validateProfileProfilename',
  },
  {
    name: 'GET_PROFILE_ID_FROM_NAME',
    state: 'getProfileIdFromName',
  },
  {
    name: 'PUBLIC_VALIDATE_PROFILE_USERNAME',
    state: 'publicValidateProfileProfilename',
  },
  {
    name: 'PUBLIC_VALIDATE_USER_EMAIL',
    state: 'publicValidateUserEmail',
  },
  {
    name: 'PUBLIC_GET_USER_FROM_PROFILENAME',
    state: 'publicGetUserFromProfilename',
  },
  {
    name: 'FOLLOW_PROFILE',
    state: 'followProfile',
  },
  {
    name: 'FOLLOW_STOCK_CHANGE',
    state: 'followStockChange',
  },
  {
    name: 'GET_FOLLOWED_INDUSTRIES',
    state: 'followedIdustries',
  },
  {
    name: 'RATE_PROFILE',
    state: 'rateProfile',
  },
  {
    name: 'GET_PROFILE_FOLLOWERS',
    state: 'getProfileFollowers',
  },
  {
    name: 'GET_PROFILE_FOLLOWED',
    state: 'getProfileFollowed',
  },
  {
    name: 'WTP_MODAL_SEEN',
    state: 'seenWTPModal',
  },
  {
    name: 'DELETE_PROFILE',
    state: 'deleteProfile',
  },
  {
    name: 'REPORT_PROFILE',
    state: 'reportProfile',
  },
  {
    name: 'GET_REPORTS',
    state: 'getReports',
  },
  {
    name: 'GET_BLOCKS',
    state: 'getBlocks',
  },
  {
    name: 'GET_COMPANY_PORTFOLIOS',
    state: 'getCompanyPortfolios',
  },
  {
    name: 'GET_COMPANY_PROFILE_COMMENTS',
    state: 'getCompanyProfileComments',
  },
  {
    name: 'GET_PROFILE_FOLLOWED_COUNT_PER_TYPE',
    state: 'getProfileFollowedCountPerType',
  },

  //================================================
  // Users
  //================================================
  {
    name: 'UPDATE_USERS_ME',
    state: 'updateUsersMe',
  },
  {
    name: 'PATCH_USER_BIRTHDATE',
    state: 'patchUserBirthdate',
  },
  {
    name: 'DELETE_USER',
    state: 'deleteUser',
  },

  //================================================
  // Search
  //================================================
  {
    name: 'SEARCH',
    state: 'search',
  },
  { name: 'SEARCH_HISTORY', state: 'searchHistory' },

  //================================================
  // Portfolio
  //================================================
  {
    name: 'CREATE_PORTFOLIO',
    state: 'createPortfolio',
  },
  {
    name: 'GET_MY_FAVORITE_PORTFOLIOS',
    state: 'getMyFavoritesPortfolios',
  },
  {
    name: 'GET_FAVORITE_PORTFOLIO',
    state: 'getFavoritesPortfolio',
  },
  {
    name: 'FAVORITE_PORTFOLIO',
    state: 'favoritePortfolio',
  },
  {
    name: 'GET_PORTFOLIO_TOP_GAINERS',
    state: 'getPortfolioTopGainers',
  },
  {
    name: 'GET_PORTFOLIO_TOP_GAINERS_FOLLOWED',
    state: 'getPortfolioTopGainersFollowed',
  },
  {
    name: 'GET_PORTFOLIO_MONTHLY_TOP_GAINERS',
    state: 'getPortfolioMonthlyTopGainers',
  },
  {
    name: 'GET_TOP_INVESTORS',
    state: 'getTopInvestors',
  },
  {
    name: 'PORTFOLIO_SEARCH',
    state: 'portfolioSearch',
  },
  {
    name: 'GET_PORTFOLIO_COMPARISON_GRAPH_INFO',
    state: 'getPortfolioComparisonGraphInfo',
  },
  {
    name: 'ORDER_PORTFOLIOS',
    state: 'orderPortfolios',
  },
  {
    name: 'GET_PORTFOLIO_BY_ID',
    state: 'getPortfolioById',
  },
  {
    name: 'REMOVE_PORTFOLIO',
    state: 'removePortfolio',
  },
  {
    name: 'LIKE_PORTFOLIO',
    state: 'likePortfolio',
  },
  {
    name: 'TOGGLE_COMPLETE_VIEW',
    state: 'toggleCompleteView',
  },
  {
    name: 'UPDATE_PORTFOLIO',
    state: 'updatePortfolio',
  },
  {
    name: 'GET_PORTFOLIO_GRAPH',
    state: 'getPortfolioGraph',
  },
  {
    name: 'GET_MY_PORTFOLIOS',
    state: 'getMyPortfolios',
  },
  {
    name: 'GET_PROFILE_PORTFOLIOS',
    state: 'getProfilePortfolios',
  },
  {
    name: 'CREATE_OPERATION',
    state: 'createOperation',
  },
  {
    name: 'CREATE_CASH_OPERATION',
    state: 'createCashOperation',
  },
  {
    name: 'ADD_PORTFOLIO_ITEM',
    state: 'addPortfolioItem',
  },
  {
    name: 'REMOVE_PORTFOLIO_ITEM',
    state: 'removePortfolioItem',
  },
  {
    name: 'REMOVE_PORTFOLIO_CASH_ITEM',
    state: 'removePortfolioCashItem',
  },
  {
    name: 'GET_CRYPTO_TICKER_STOCK_VALUE',
    mutation: 'getCryptoTickerStockValue',
  },
  {
    name: 'GET_TICKER_STOCK_VALUE',
    mutation: 'getTickerStockValue',
  },
  {
    name: 'GET_MULTIPLE_TICKER_STOCK_VALUE',
    mutation: 'getMultipleTickerStockValue',
  },
  {
    name: 'GET_MULTIPLE_TICKER_STOCK_VALUE_WITH_OVERALL',
    mutation: 'getMultipleTickerStockValueWithOverall',
  },
  {
    name: 'GET_TEAM_PORTFOLIO_PROFILES',
    mutation: 'getTeamPortfolioProfiles',
  },
  {
    name: 'UPDATE_TEAM_PORTFOLIO_PROFILE',
    mutations: 'updateTeamPortfolioProfile',
  },
  {
    name: 'DELETE_TEAM_PORTFOLIO_PROFILE',
    mutation: 'deleteTeamPortfolioProfile',
  },
  {
    name: 'CREATE_TEAM_PORTFOLIO_PROFILE',
    mutation: 'createTeamPortfolioProfile',
  },
  {
    name: 'GET_SIMILAR_TICKERS',
    mutations: 'getSimilarTickers',
  },
  {
    name: 'GET_CRYPTO_TICKER_VALUE',
    mutation: 'getCryptoTickerStockRangeValue',
  },
  {
    name: 'GET_TICKER_VALUE',
    mutation: 'getTickerStockRangeValue',
  },
  {
    name: 'GET_TICKER_VALUE_WITH_OVERALL',
    mutation: 'getTickerStockRangeWithOverall',
  },
  {
    name: 'GET_PORTFOLIO_COMMENTS',
    mutation: 'getPortfolioComments',
  },
  {
    name: 'GET_PORTFOLIO_ITEMS_GAIN_FOR_INTERVAL',
    mutation: 'getPortfolioItemsGainForInterval',
  },

  //================================================
  // Post
  //================================================
  {
    name: 'GET_POST_BY_ID',
    state: 'getPostById',
  },
  {
    name: 'GET_POSTS',
    state: 'getPosts',
  },
  {
    name: 'GET_POST_REPLIES',
    state: 'getPostReplies',
  },
  {
    name: 'CREATE_POST',
    state: 'createPost',
  },
  {
    name: 'UPDATE_POST',
    state: 'updatePost',
  },
  {
    name: 'REPORT_POST',
    state: 'reportPost',
  },
  {
    name: 'UPLOAD_POST_IMAGE',
    state: 'uploadPostImage',
  },
  {
    name: 'UPLOAD_PORTFOLIO_IMAGE',
    state: 'uploadPortfolioImage',
  },
  {
    name: 'LIKE_POST',
    state: 'likePost',
  },
  {
    name: 'FAVORITE_POST',
    state: 'favoritePost',
  },
  {
    name: 'GET_MY_FAVORITE_POST',
    state: 'getMyFavoritesPost',
  },
  {
    name: 'GET_PROFILE_POSTS',
    state: 'getProfilePosts',
  },
  {
    name: 'GET_POLL',
    mutation: 'getPoll',
  },
  {
    name: 'GET_POLL_RESULTS',
    mutation: 'getPollResults',
  },
  {
    name: 'CREATE_POLL',
    mutation: 'createPoll',
  },
  {
    name: 'VOTE_POLL',
    mutation: 'votePoll',
  },

  //================================================
  // Notifications
  //================================================
  {
    name: 'GET_NOTIFICATIONS',
    state: 'notifications',
  },
  {
    name: 'HANDLE_READ_ALL_NOTIFICATION',
    state: 'handleReadAllNotification',
  },
  {
    name: 'HANDLE_READ_MULTIPLE_NOTIFICATIONS',
    state: 'handleReadMultipleNotifications',
  },
  {
    name: 'HANDLE_READ_NOTIFICATION',
    state: 'handleReadNotification',
  },
  {
    name: 'DELETE_NOTIFICATION',
    state: 'removeNotification',
  },
  {
    name: 'GET_NOTIFICATIONS_COUNT',
    state: 'notificationsCount',
  },

  //================================================
  // Onboarding
  //================================================
  {
    name: 'TUTORIAL_NEXT_STEP',
    state: 'tutorialNextStep',
  },
  {
    name: 'TUTORIAL_PREVIOUS_STEP',
    state: 'tutorialPreviousStep',
  },
  {
    name: 'COMPLETE_TUTORIAL',
    state: 'completeTutorial',
  },
  {
    name: 'TIPS_NEXT_STEP',
    state: 'tipsNextStep',
  },
  {
    name: 'COMPLETE_TIPS',
    state: 'completeTips',
  },
  {
    name: 'GET_USER_ONBOARDING_QUESTIONS',
    mutation: 'getUserOnboardingQuestions',
  },
  {
    name: 'GET_USER_ONBOARDING_ANSWERS',
    mutation: 'getUserOnboardingAnswersByProfileId',
  },
  {
    name: 'SAVE_USER_ONBOARDING_ANSWERS',
    mutation: 'saveUserOnboardingAnswers',
  },

  //================================================
  // Recommendations
  //================================================
  {
    name: 'GET_RECOMMENDED_PROFILES',
    state: 'getRecommendedProfiles',
  },
  {
    name: 'GET_RECOMMENDED_COMPANIES',
    state: 'getRecommendedCompanies',
  },
  {
    name: 'GET_MUTUAL_FOLLOWS_RECOMMENDATION',
    state: 'getMutualFollowsRecommendation',
  },
  {
    name: 'GET_MUTUAL_INDUSTRIES_RECOMMENDATION',
    state: 'getMutualIndustriesRecommendation',
  },
  {
    name: 'GET_COMPANY_FOLLOWERS_RECOMENDAITON',
    state: 'getCompanyFollowersRecommendation',
  },
  {
    name: 'GET_SIMILAR_COMPANIES_RECOMMENDATION',
    state: 'getSimilarCompaniesRecommendation',
  },
  //================================================
  // Languages
  //================================================
  {
    name: 'GET_NEWS_SUSCRIBED_LANGUAGES',
    state: 'getNewsSuscribedLanguages',
  },
  //================================================
  // Stocks
  //================================================
  {
    name: 'SEARCH_STOCKS',
    state: 'searchStocks',
  },

  //================================================
  // Leagues
  //================================================
  {
    name: 'CREATE_ADMIN_LEAGUE',
    state: 'createAdminLeague',
  },
  {
    name: 'SEARCH_LEAGUES',
    state: 'searchLeagues',
  },
  {
    name: 'GET_PENDING_PROFILE_INVITATIONS',
    state: 'getPendingProfileInvitations',
  },
  {
    name: 'ANSWER_LEAGUE_INVITATION',
    state: 'answerLeagueInvitation',
  },
  {
    name: 'GET_LEAGUE_BY_ID',
    mutation: 'getLeagueById',
  },
  {
    name: 'GET_LEAGUE_RULES_BY_ID',
    mutation: 'getLeagueRulesById',
  },
  {
    name: 'GET_PROFILE_LEADERBOARD',
    mutation: 'getProfileLeaderboard',
  },
]);
