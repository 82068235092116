import { render, staticRenderFns } from "./UIPortfolioCard.vue?vue&type=template&id=6d1c67e3"
import script from "./UIPortfolioCard.vue?vue&type=script&lang=js"
export * from "./UIPortfolioCard.vue?vue&type=script&lang=js"
import style0 from "./UIPortfolioCard.vue?vue&type=style&index=0&id=6d1c67e3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileImg: require('/skade-pwa/components/ui/atom/ProfileImg.vue').default,PortfolioRankTag: require('/skade-pwa/components/ui/atom/PortfolioRankTag.vue').default,PortfolioPrivacyTag: require('/skade-pwa/components/ui/atom/PortfolioPrivacyTag.vue').default,ChangePercent: require('/skade-pwa/components/ui/atom/ChangePercent.vue').default,Truncate: require('/skade-pwa/components/ui/atom/Truncate.vue').default})
