import { _maxPortfolioLimit } from '../portfolio-settings';

/**
 * Returns the reason why the user cannot create a portfolio
 * @param emailConfirmed Whether the user has confirmed their email
 * @param ownedPortfolios The number of portfolios the user owns
 * @returns The reason why the user cannot create a portfolio
 */
export function cannotCreatePortfolioReason(
  emailConfirmed: boolean,
  ownedPortfolios: number | undefined
) {
  if (!emailConfirmed) return 'create_portfolio_disabled_mail_validation';
  if (portfolioLimitReached(ownedPortfolios)) return portfolioLimitReachedError;
  return '';
}

/**
 * The error code that is returned when the user has reached the portfolio limit
 * @constant
 * @type {string}
 */
export const portfolioLimitReachedError = 'portfolio_limit_reached';

/**
 * The error code that is returned when the portfolio name is already used
 * @constant
 * @type {string}
 */
export const portfolioUniqueNameError = 'portfolio_name_already_used';

/**
 * Returns whether the user can create a portfolio
 * @param emailConfirmed Whether the user has confirmed their email
 * @param ownedPortfolios The number of portfolios the user owns
 * @returns Whether the user can create a portfolio
 */
export function canCreatePortfolio(
  emailConfirmed: boolean,
  ownedPortfolios: number | undefined
) {
  return emailConfirmed && !portfolioLimitReached(ownedPortfolios);
}

/**
 * Returns whether the user has reached the portfolio limit
 * @param ownedPortfolios The number of portfolios the user owns
 * @returns Whether the user has reached the portfolio limit
 */
export function portfolioLimitReached(ownedPortfolios: number | undefined) {
  return ownedPortfolios && ownedPortfolios >= _maxPortfolioLimit;
}

/**
 * Returns the maximum portfolio limit for free users
 */
export const maxPortfoliosPerFreeUser = _maxPortfolioLimit;
