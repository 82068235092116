
import UINewsPost from '@/components/ui/organism/UINewsPost';
import UIProfilePost from '@/components/ui/organism/UIProfilePost';
import UIDeletedPost from '@/components/ui/organism/UIDeletedPost';
import UIPortfolioCard from '@/components/ui/molecule/PortfolioCard/UIPortfolioCard';
import { getMedia, isMobileResponsive } from '@/utils/mixins';
import { generatePostUrl } from '@/utils/url-generator';
import { formatPostContent } from '~/utils/links';
import { isMobile } from '~/utils/screenSizes';
import { redirectToPost } from '~/utils/route-redirects';

export default {
  name: 'UIPost',
  components: {
    UIProfilePost,
    UINewsPost,
    UIDeletedPost,
    UIPortfolioCard,
  },

  mixins: [getMedia, isMobileResponsive],
  props: {
    post: {
      type: Object,
      default: () => null,
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
    isSocialInteractionDisabled: {
      type: Boolean,
      default: false,
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    isPostPage: {
      type: Boolean,
      default: false,
    },
    showReplies: {
      type: Boolean,
      default: false,
    },
    showCard: {
      type: Boolean,
      default: true,
    },
    portfolio: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      mentionIds: [],
      mentionedPost: null,
      openMoreMenu: false,
      icoRegex: /([a-zA-Z0-9\s_\\.\-():])+(.ico)$/i,
    };
  },
  computed: {
    routeName() {
      return this.$route.name?.split('_')[0];
    },
    isMobileSize() {
      return isMobile();
    },
  },
  methods: {
    goToPost() {
      this.$emit('goToPost');
    },
    onUserClick() {
      this.$emit('onUserClick');
    },

    mouseEnterMention(payload) {
      this.$emit('mouseEnterMention', {
        pos: payload.pos,
        profile: payload.profile,
        id: payload.id,
      });
    },
    mouseOutMention(payload) {
      this.$emit('mouseOutMention', {
        pos: payload.pos,
        id: payload.id,
      });
    },
    likePost() {
      this.$emit('onLike');
    },
    onReply() {
      if (this.isMobileSize) {
        redirectToPost(this.post.postId);
      } else {
        this.$emit('onReply');
      }
    },
    onRepost() {
      this.$emit('onRepost');
    },
    onShare() {
      {
        if (navigator.share) {
          navigator.share({
            url: generatePostUrl(this.post.postId),
          });
        }
      }
    },
    handleWriteComment(payload) {
      this.$emit('onWriteComment', payload);
    },
    formatPostContent,
  },
};
