
import { mapActions, mapGetters } from 'vuex';
import CustomModal from '~/components/ui/molecule/CustomModal.vue';
import CreatePortfolioAssetOperationModal from '@/components/ui/modal/CreatePortfolioOperationModal/CreatePortfolioAssetOperationModal';
import CreatePortfolioCashOperationModal from '@/components/ui/modal/CreatePortfolioOperationModal/CreatePortfolioCashOperationModal';
import CreatePortfolioOperationModalHeader from '@/components/ui/modal/CreatePortfolioOperationModal/CreatePortfolioOperationModalHeader';
import CreatePortfolioOperationModalFooter from '@/components/ui/modal/CreatePortfolioOperationModal/CreatePortfolioOperationModalFooter';
import { isMobile } from '~/utils/screenSizes';

/**
 * The main portfolio operation modal.
 * @todo Refactor this into two components -- Stock and Crypto.
 */
export default {
  name: 'CreatePortfolioOperationModalWrapper',
  components: {
    CreatePortfolioCashOperationModal,
    CreatePortfolioAssetOperationModal,
    CreatePortfolioOperationModalHeader,
    CreatePortfolioOperationModalFooter,
    CustomModal,
  },
  data() {
    return {
      formDate: null,
      maxDate: this.$dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
      daysToSubstract: 2,
      shouldShowTickerSearch: false,
      shouldShowPortfolioSearch: false,
      valueLoading: true,
      cashAvailableAtDate: {},
      availablePortfolioCurrencies: [],
      formButtonState: 'disabled',
      formButtonTooltip: '',
      totalAmount: 0,
      currentChosenCurrency: '',
      transactionError: false,
    };
  },
  async fetch() {
    if (process.client) {
      if (!this.createPortfolioOperationModal.portfolio) {
        await this.getMyPortfolios();

        await this.getPortfolioById(
          this.getMyPortfoliosRes.data.portfolios[0].id
        );
        this.setCreateOperationModalModalPortfolio(
          this.getPortfolioByIdRes.data
        );
      }

      this.formDate = this.maxDate;
      await this.handleDateChange(this.formDate, null);
    }
  },
  computed: {
    ...mapGetters('portfolio', [
      'getPortfolioByIdRes',
      'getMyPortfoliosRes',
      'createOperationRes',
      'createCashOperationRes',
      'addPortfolioItemRes',
    ]),
    ...mapGetters('modals', ['createPortfolioOperationModal']),
    isCrypto() {
      return this.createPortfolioOperationModal.portfolioType == 'Crypto';
    },
    tickerToAdd() {
      this.setCurrencyByStock(
        this.createPortfolioOperationModal.tickerValue?.currency
      );
      return this.createPortfolioOperationModal.tickerValue == ''
        ? null
        : this.createPortfolioOperationModal.tickerValue;
    },
    isMobileSize() {
      return isMobile();
    },
  },
  created() {
    this.getMaxDate();
    this.shouldShowTickerSearch =
      !this.createPortfolioOperationModal.symbol ||
      this.createPortfolioOperationModal.isSuggestedTicker;
    this.shouldShowPortfolioSearch =
      !this.createPortfolioOperationModal.portfolio;
  },
  methods: {
    ...mapActions('portfolio', [
      'getMyPortfolios',
      'addPortfolioItem',
      'getPortfolioById',
      'createOperation',
      'createCashOperation',
    ]),
    ...mapActions('modals', [
      'showCreatePortfolioOperationModal',
      'switchCreatePortfolioOperation',
      'setOperationSymbol',
      'getTickerStockValue',
      'getCryptoTickerStockValue',
      'setCreateOperationModalModalPortfolio',
      'removeSymbolFromPortfolioOperation',
      'resetSubmitOperationState',
      'setNoDataAtDateError',
    ]),
    ...mapActions('modals', ['showPortfolioAssetOperationModalFooter']),
    async handleSubmit(data, shouldStayOpen, callback, isCashTransaction) {
      this.transactionError = false;
      const portfolioId = this.getPortfolioByIdRes.data.id;

      if (this.createPortfolioOperationModal.operationType === 'Sell') {
        data.chosenCurrency =
          this.createPortfolioOperationModal.tickerValue?.currency;
      }

      this.itemPayload = {
        portfolioId: portfolioId,
        symbolId: this.createPortfolioOperationModal.symbol.symbolId,
        companyProfileId: this.createPortfolioOperationModal.symbol.companyId,
        cryptocurrencyId: this.isCrypto
          ? this.createPortfolioOperationModal.symbol.companyId
          : null,
        ItemType: this.isCrypto ? 'Cryptocurrency' : 'Stocks',
        itemPrice: data.price,
        itemQuantity: data.quantity,
        date: data.date,
        isCashTransaction: isCashTransaction,
        chosenCurrency: data.chosenCurrency,
        // tickerCurrencyCode:
        //   this.createPortfolioOperationModal.tickerValue.currency,
      };
      this.operationPayload = {
        companyId: this.createPortfolioOperationModal.symbol.companyId,
        portfolioId: portfolioId,
        isCashTransaction: isCashTransaction,
        ...data,
      };
      if (this.getPortfolioByIdRes.data) {
        const item = this.getPortfolioByIdRes.data.items.find(
          (e) =>
            e.symbolId === this.createPortfolioOperationModal.symbol.symbolId
        );

        if (item === undefined) {
          if (data.operationType === 'Sell') {
            window.$nuxt.$notify.warning(
              this.$t('operation_sell_without_shares')
            );
            return;
          } else {
            await this.addPortfolioItem(this.itemPayload);
          }
        } else {
          await this.createOperation({
            itemId: item.id,
            ...this.operationPayload,
          });
        }
        this.resetSubmitOperationState();
        // setting transaction error message
        if (this.addPortfolioItemRes?.error || this.createOperationRes?.error) {
          this.transactionError = true;
        }
        if (this.transactionError) {
          return;
        } else {
          if (shouldStayOpen) {
            await this.getPortfolioById(portfolioId);
            this.setCreateOperationModalModalPortfolio(
              this.getPortfolioByIdRes.data
            );
            this.getCashAvailable();
            callback();
          } else {
            this.handleClose();
          }
        }
      }
    },
    async handleCashSubmit(data, shouldStayOpen, callback) {
      const portfolioId = this.getPortfolioByIdRes.data.id;
      const toSend = {
        portfolioId: portfolioId,
        ...data,
      };
      await this.createCashOperation(toSend);
      if (shouldStayOpen) {
        await this.getPortfolioById(portfolioId);
        this.getCashAvailable();
        callback();
      } else {
        this.handleClose();
      }
      this.resetSubmitOperationState();
    },
    handleClose() {
      this.setNoDataAtDateError(false);
      const payload = {
        symbol: null,
        portfolioType: null,
        show: false,
        currency: '',
      };
      this.showCreatePortfolioOperationModal(payload);
    },
    // @todo Figure out what name is? Name of portfolio, I assume. Document format.
    async handlePortfolioChange(name, callback) {
      if (!name) {
        return;
      }

      await this.getPortfolioById(
        this.getMyPortfoliosRes.data.portfolios.find((p) => p.name == name).id
      );
      this.setCreateOperationModalModalPortfolio(this.getPortfolioByIdRes.data);

      this.getCashAvailable();

      // refreshing symbol price/conversions upon portfolio change
      await this.handleSymbolChange(
        this.createPortfolioOperationModal.symbol,
        callback
      );
    },
    /** TODO: Remove these callbacks with v-model... */
    async handleDateChange(date, callback) {
      if (
        !this.createPortfolioOperationModal.portfolio ||
        this.formdate == date
      ) {
        return;
      }
      this.formDate = date;
      this.getCashAvailable();
      if (!this.createPortfolioOperationModal.symbol) return;
      const symbolName = this.createPortfolioOperationModal.symbol.name
        ? this.createPortfolioOperationModal.symbol.name
        : this.createPortfolioOperationModal.symbol.symbol;
      const payload = {
        symbol: symbolName,
        date: date,
        portfolioId: this.createPortfolioOperationModal.portfolio.id,
        forceEodUpdate: true,
      };
      await (this.isCrypto
        ? this.getCryptoTickerStockValue(payload)
        : this.getTickerStockValue(payload));
      this.valueLoading = false;
      typeof callback === 'function' && callback();
    },
    /** TODO: Remove these callbacks with v-model... */
    async handleSymbolChange(symbol, callback) {
      this.setOperationSymbol(symbol);
      await this.handleDateChange(this.formDate, callback);
    },
    getCashAvailable() {
      const currency = this.createPortfolioOperationModal.portfolio.currency;
      const cashItems = this.getPortfolioByIdRes.data.cashItems;
      const nextDay = this.$dayjs(this.formDate).add(1, 'day');
      this.cashAvailableAtDate = {};
      this.availablePortfolioCurrencies = [];

      cashItems.forEach((ci) => {
        const ciCurrencySymbol = this.$display.money.getCurrencySymbol(
          ci.currencyCode
        );
        const availableValueAtDate = ci.operations
          .filter((o) => this.$dayjs(o.operationDate).isBefore(nextDay))
          .reduce((sum, op) => sum + op.operationAmount, 0);

        this.cashAvailableAtDate[ci.currencyCode] = {
          value: availableValueAtDate,
          symbol: ciCurrencySymbol,
          conversion:
            currency !== ci.currencyCode
              ? availableValueAtDate * ci.currentConversionRate
              : availableValueAtDate,
        };
        this.availablePortfolioCurrencies.push(ci.currencyCode);
      });
    },
    getDefaultCurrency() {
      const defaultCurrency = 'EUR';
      if (this.createPortfolioOperationModal.currency !== '') {
        return this.createPortfolioOperationModal.currency;
      }
      return this.createPortfolioOperationModal.portfolio
        ? this.createPortfolioOperationModal.portfolio.currency
        : defaultCurrency;
    },
    getMaxDate() {
      const today = this.$dayjs().$d.getDay();
      // getDay() returns 0-6 (SUNDAY IS 0!!!)
      let toSubstract = 1;
      if (today === 1) {
        toSubstract = 3;
      } else if (today === 0) {
        toSubstract = 2;
      }
      this.daysToSubstract = toSubstract;
      this.maxDate = this.$dayjs()
        .subtract(toSubstract, 'day')
        .format('YYYY-MM-DD');
    },
    switchOperation(isCashTransaction) {
      const payload = {
        portfolio: this.getPortfolioByIdRes.data,
        portfolioType: this.getPortfolioByIdRes.data.portfolioType,
        show: true,
        isCashTransaction: isCashTransaction,
        shouldShowOperationSwitcher:
          this.createPortfolioOperationModal.shouldShowOperationSwitcher,
        shouldShowOperationButtons:
          this.createPortfolioOperationModal.shouldShowOperationButtons,
      };
      this.switchCreatePortfolioOperation(payload);
    },
    handleChangeOperationType(operation) {
      switch (operation.key) {
        case 'Stock':
          this.switchOperation(false);
          break;
        case 'Cash':
          this.switchOperation(true);
          break;
        default:
          return;
      }
    },
    handleButtonStateChange(buttonState) {
      this.formButtonState = buttonState;
    },
    handleButtonTooltipchange(buttonTooltip) {
      this.formButtonTooltip = buttonTooltip;
    },
    handleCurrencyChange(chosenCurrency) {
      if (this.createPortfolioOperationModal.operationType === 'Sell') {
        this.setCurrencyByStock(
          this.createPortfolioOperationModal.tickerValue?.currency
        );
      } else this.currentChosenCurrency = chosenCurrency;
    },
    handleAmountChange(amount) {
      this.totalAmount = amount;
    },
    handleRemoveSymbol() {
      this.removeSymbolFromPortfolioOperation();
      this.showPortfolioAssetOperationModalFooter(false);
      this.scrollSearchSymbolWrapperIntoView();
      this.setNoDataAtDateError(false);
    },
    scrollSearchSymbolWrapperIntoView() {
      setTimeout(() => {
        const wrapper = document.getElementById('searchSymbolWrapperId');
        if (wrapper) {
          wrapper.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, 200);
    },
    handleTransactionTypeChange(operationType) {
      this.createPortfolioOperationModal.operationType = operationType;
    },
    setCurrencyByStock(currency) {
      if (this.createPortfolioOperationModal.operationType === 'Sell') {
        this.currentChosenCurrency =
          this.$display.money.getCurrencySymbol(currency);
      }
    },
  },
};
