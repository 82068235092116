//===============================
// Debounce
//===============================
export function debounce(fn, wait) {
  let t;
  return function (...args) {
    clearTimeout(t);
    t = setTimeout(() => fn.apply(this, args), wait);
  };
}

//===============================
// Optimize image file
//
// Parameter:
// originalImage : File
//
// Return:
// data:image/jpeg:base64
//===============================
export function optimizeImg(originalImage) {
  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      const canvas = document.createElement('canvas');

      canvas.width = image.width;
      canvas.height = image.height;

      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0);

      resolve(
        b64toBlob(
          canvas.toDataURL('image/jpeg', 0.6).split(',')[1],
          'image/jpeg'
        )
      );
    };
    image.src = URL.createObjectURL(originalImage);
  });
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

// convert the result of optimizeImg (Blob) to base64
export async function blob2base64(blob) {
  const reader = new FileReader();
  await new Promise((resolve, reject) => {
    reader.onload = resolve;
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

  return reader.result;
}

// This function fills the template object with the src object
// but only if template contains the same key as src
export function fill(template, src) {
  Object.keys(template).forEach((key) => {
    template[key] = src[key] || template[key];
  });
}

// I generate the UID from two parts here
// to ensure the random number provide enough bits.
export function generateUID() {
  let firstPart = (Math.random() * 46656) | 0;
  let secondPart = (Math.random() * 46656) | 0;
  firstPart = ('000' + firstPart.toString(36)).slice(-3);
  secondPart = ('000' + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
}

// find and replace item in list
export function findAndReplace(list, id1, id2, newElem) {
  if (list) {
    const index = list.findIndex((e) => e[id1] === id2);

    if (index !== -1) {
      const res = [...list];

      res[index] = newElem;
      return res;
    }
  }
}

export function findAndDelete(list, id1, id2) {
  if (list) {
    const index = list.findIndex((e) => e[id1] === id2);
    if (index !== -1) {
      list.splice(index, 1);
      return list;
    }
  }
}
