import { showErrorToast } from '~/utils/toast-notifications';

export default {
  SET_USER_SEARCH_ERROR: (state, error) => {
    state.users.error = error;
    showErrorToast(window.$nuxt.$t('error_user_search'));
  },
  SET_COMPANY_SEARCH_ERROR: (state, error) => {
    state.companies.error = error;
    showErrorToast(window.$nuxt.$t('error_company_search'));
  },
  SET_CRYPTO_SEARCH_ERROR: (state, error) => {
    state.cryptos.error = error;
    showErrorToast(window.$nuxt.$t('error_crypto_search'));
  },
  SET_TICKER_SEARCH_ERROR: (state, error) => {
    state.tickers.error = error;
    showErrorToast(window.$nuxt.$t('error_ticker_search'));
  },
  SET_HASHTAG_SEARCH_ERROR: (state, error) => {
    state.hashtags.error = error;
    showErrorToast(window.$nuxt.$t('error_hashtag_search'));
  },
  SET_INDICES_SEARCH_ERROR: (state, error) => {
    state.cryptos.error = error;
    showErrorToast(window.$nuxt.$t('error_indices_search'));
  },

  SET_SEARCH_LOADING(state, loading) {
    state.searchLoading = loading;
  },

  SET_SEARCH: (state, payload) => {
    if (payload.mentions) state.users.data = payload.mentions;
    if (payload.companies) state.companies.data = payload.companies;
    if (payload.cryptos) state.cryptos.data = payload.cryptos;
    if (payload.symbolsWithPrices)
      state.tickers.data = payload.symbolsWithPrices;
    if (payload.hashtags) state.hashtags.data = payload.hashtags;
    if (payload.indices) state.indices.data = payload.indices;
    if (payload.symbols) state.symbols = payload.symbols;
  },
  SET_SEARCH_ERROR(state, error) {
    state.searchError = error;
    console.error('Error in search', error);

    // window.$nuxt.$notify.error('error in search');
  },

  APPEND_USERS_TO_SEARCH: (state, payload) => {
    if (payload && payload.length > 0)
      state.users.data = state.users.data.concat(payload);
  },

  APPEND_COMPANIES_TO_SEARCH: (state, payload) => {
    if (payload && payload.length > 0)
      state.companies.data = [...state.companies.data, ...payload];
  },
  APPEND_CRYPTOS_TO_SEARCH: (state, payload) => {
    if (payload && payload.length > 0)
      state.cryptos.data = [...state.cryptos.data, ...payload];
  },
  APPEND_TICKERS_TO_SEARCH: (state, payload) => {
    if (payload && payload.length > 0)
      state.tickers.data = [...state.tickers.data, ...payload];
  },
  APPEND_HASHTAGS_TO_SEARCH: (state, payload) => {
    if (payload && payload.length > 0)
      state.hashtags.data = [...state.hashtags.data, ...payload];
  },
  APPEND_INDICES_TO_SEARCH: (state, payload) => {
    if (payload && payload.length > 0)
      state.indices.data = [...state.indices.data, ...payload];
  },

  SET_FOLLOW: (state, payload) => {
    if (payload.profileType === 'user') {
      const index = state.users.data.findIndex(
        (r) => r.profileId == payload.profileId
      );
      state.users.data[index].isFollowed = !state.users.data[index].isFollowed;
    }
    if (payload.profileType === 'company') {
      const index = state.companies.data.findIndex(
        (r) => r.profileId == payload.profileId
      );
      state.companies.data[index].isFollowed =
        !state.companies.data[index].isFollowed;
    }
    if (payload.profileType === 'crypto') {
      const index = state.cryptos.data.findIndex(
        (r) => r.profileId == payload.profileId
      );
      state.cryptos.data[index].isFollowed =
        !state.cryptos.data[index].isFollowed;
    }
    // TODO - SKD-1405 Uncomment this when we enable indices
    if (payload.profileType === 'indices') {
      const index = state.indices.data.findIndex(
        (r) => r.profileId == payload.profileId
      );
      state.indices.data[index].isFollowed =
        !state.indices.data[index].isFollowed;
    }
  },

  SET_FOLLOW_ERROR: (state, error) => {
    state.followError = error;
    showErrorToast(window.$nuxt.$t('error_follow'));
  },

  FOLLOW_SYMBOL_STOCK_PRICE_CHANGE_SUCCESS: (state, payload) => {
    if (state.tickers.data) {
      const symbol = state.tickers.data.find(
        (symbol) => symbol.symbolId === payload.symbolId
      );

      symbol.isFollowingPriceChange = !symbol.isFollowingPriceChange;
    }
  },

  SET_COMPANY_SYMBOLS_ERROR: (state, er) => {
    state.tickers.error = er;
  },

  V2_SET_PAGE: (state, page) => {
    state.v2.page = page;
  },

  V2_SET_SEARCH: (state, payload) => {
    const tickersToAdd = mapToTickers(
      payload.symbols,
      payload.cryptos,
      payload.etfSymbols
    );
    const usersToAdd = mapToUsers(payload.mentions);

    const hasMoreTickers = tickersToAdd.length > 0;
    const hasMoreUsers = usersToAdd.length > 0;

    state.v2.tickers = deduplicateById([...tickersToAdd]);
    state.v2.users = deduplicateById([...usersToAdd]);

    state.v2.hasMoreTickers = hasMoreTickers;
    state.v2.hasMoreUsers = hasMoreUsers;
  },

  V2_APPEND_SEARCH: (state, payload) => {
    const tickersToAdd = mapToTickers(
      payload.symbols,
      payload.cryptos,
      payload.etfs
    );
    const usersToAdd = mapToUsers(payload.mentions);

    const hasMoreUsers = usersToAdd.length > 0;
    const hasMoreTickers = tickersToAdd.length > 0;

    state.v2.users = deduplicateById([...state.v2.users, ...usersToAdd]);
    state.v2.tickers = deduplicateById([...state.v2.tickers, ...tickersToAdd]);

    state.v2.hasMoreUsers = hasMoreUsers;
    state.v2.hasMoreTickers = hasMoreTickers;
  },
};

const deduplicateById = (items) => {
  return [...new Map(items.map((item) => [item.id, item])).values()];
};

const mapUser = (user) => ({
  id: user.profileId,
  profileId: user.profileId,
  avatarUri: user.avatarUri,
  title: user.displayName,
  subTitle: user.profileName,
});

const mapSymbol = (symbol) => ({
  id: symbol.symbolId,
  profileId: symbol.companyId,
  avatarUri: symbol.avatarUri,
  title: symbol.name,
  subTitle: symbol.companyName ?? '',
});

const mapCrypto = (crypto) => ({
  id: crypto.profileId,
  profileId: crypto.profileId,
  avatarUri: crypto.avatarUri,
  title: crypto.profileName,
  subTitle: crypto.industry ?? '',
});

const mapEtf = (etf) => ({
  id: etf.symbolId,
  profileId: etf.companyId,
  avatarUri: etf.avatarUri,
  title: etf.name,
  subTitle: etf.companyName ?? '',
});

const mapToUsers = (users) => [...(users ?? []).map(mapUser)];

const mapToTickers = (symbols, cryptos, etfs) => [
  ...(symbols ?? []).map(mapSymbol),
  ...(cryptos ?? []).map(mapCrypto),
  ...(etfs ?? []).map(mapEtf),
];
