export const post = (state) => state.post;
export const replies = (state) => state.replies;
export const postLoading = (state) => state.postLoading;
export const repliesLoading = (state) => state.repliesLoading;
export const nextRepliesLoading = (state) => state.nextRepliesLoading;
export const nestedRepliesLoading = (state) => state.nestedRepliesLoading;
export const nextNestedRepliesLoading = (state) =>
  state.nextNestedRepliesLoading;
export const postError = (state) => state.postError;
export const repliesError = (state) => state.repliesError;
export const isLoadFinished = (state) => state.isLoadFinished;
export const poll = (state) => state.poll;
export const uploadPostImageRes = (state) => state.uploadPostImage;
