import { findAndDelete, findAndReplace } from '@/utils';

import { FILTER_INTERVAL_KEYS } from '~/utils/filter-intervals';
import { MutationsCreator } from '@/store/utils';
import { showErrorToast } from '~/utils/toast-notifications';

export default {
  ADD_PORTFOLIO_ITEM_INIT: (state) => {
    state.addPortfolioItem = {
      loading: true,
      error: null,
      success: false,
    };
  },
  ADD_PORTFOLIO_ITEM_SUCCESS: (state) => {
    state.addPortfolioItem = {
      loading: false,
      error: null,
      success: true,
    };
  },
  ADD_PORTFOLIO_ITEM_ERROR: (state, error) => {
    state.addPortfolioItem = {
      loading: false,
      error: error,
      success: false,
    };
  },
  GET_TICKER_STOCK_VALUE_INIT: (state) => {
    state.getTickerStockValue.data = null;
    state.getTickerStockValue.error = null;
    state.getTickerStockValue.loading = true;
  },
  GET_TICKER_STOCK_VALUE_SUCCESS: (state, data) => {
    state.getTickerStockValue.data = data;
    state.getTickerStockValue.loading = false;
  },
  GET_TICKER_STOCK_VALUE_INIT_ERROR: (state, error) => {
    state.getTickerStockValue.error = error;
    state.getTickerStockValue.loading = false;
  },
  GET_SIMILAR_TICKERS_INIT: (state) => {
    state.getSimilarTickers.data = [];
    state.getSimilarTickers.error = null;
    state.getSimilarTickers.loading = true;
  },
  GET_SIMILAR_TICKERS_SUCCESS: (state, data) => {
    state.getSimilarTickers.data = data;
    state.getSimilarTickers.loading = false;
  },
  GET_SIMILAR_TICKERS_INIT_ERROR: (state, error) => {
    state.getSimilarTickers.error = error;
    state.getSimilarTickers.loading = false;
  },
  SET_GET_TICKER_VALUE_LOADING: (state, loading) => {
    state.getTickerStockRangeValue.loading = loading;
  },
  /**
   * This is currently required in order not to break the stock charts, but it should be refactored out.
   * @deprecated We should avoid using this since it prevents implementing more complex behaviors - e.g. SKD-1533
   */
  GET_TICKER_VALUE_INIT: (state) => {
    state.getTickerStockRangeValue.data = null;
    state.getTickerStockRangeValue.error = null;
    state.getTickerStockRangeValue.loading = true;
  },
  SET_GET_TICKER_VALUE_WITH_PERFORMANCE_LOADING: (state, loading) => {
    state.getTickerStockRangeWithOverall.loading = loading;
  },
  /**
   * This is currently required in order not to break the stock charts, but it should be refactored out.
   * @deprecated We should avoid using this since it prevents implementing more complex behaviors - e.g. SKD-1533
   */
  GET_TICKER_VALUE_WITH_PERFORMANCE_INIT: (state) => {
    state.getTickerStockRangeWithOverall.data = null;
    state.getTickerStockRangeWithOverall.error = null;
    state.getTickerStockRangeWithOverall.loading = true;
  },
  GET_TICKER_VALUE_SUCCESS: (state, data) => {
    state.getTickerStockRangeValue.data = data;
    state.getTickerStockRangeValue.loading = false;
  },
  GET_TICKER_VALUE_WITH_PERFORMANCE_SUCCESS: (state, data) => {
    state.getTickerStockRangeWithOverall.data = data;
    state.getTickerStockRangeWithOverall.loading = false;
  },
  GET_TICKER_VALUE_INIT_ERROR: (state, error) => {
    state.getTickerStockRangeValue.error = error;
    state.getTickerStockRangeValue.loading = false;
  },
  GET_TICKER_VALUE_WITH_PERFORMANCE_ERROR: (state, error) => {
    state.getTickerStockRangeWithOverall.error = error;
    state.getTickerStockRangeWithOverall.loading = false;
  },
  GET_MULTIPLE_TICKER_VALUE_SUCCESS: (state, data) => {
    state.getMultipleTickerStockRangeValue.data = data;
    state.getMultipleTickerStockRangeValue.loading = false;
  },
  GET_MULTIPLE_TICKER_VALUE_INIT_ERROR: (state, error) => {
    state.getMultipleTickerStockRangeValue.error = error;
    state.getMultipleTickerStockRangeValue.loading = false;
  },
  GET_MULTIPLE_TICKER_VALUE_WITH_PERFORMANCE_SUCCESS: (state, data) => {
    state.getMultipleTickerStockRangeValueWithOverall.data = data;
    state.getMultipleTickerStockRangeValueWithOverall.loading = false;
  },
  GET_MULTIPLE_TICKER_VALUE_WITH_PERFORMANCE_ERROR: (state, error) => {
    state.getMultipleTickerStockRangeValueWithOverall.error = error;
    state.getMultipleTickerStockRangeValueWithOverall.loading = false;
  },
  GET_TEAM_PORTFOLIO_PROFILES_INIT: (state) => {
    state.getTeamPortfolioProfilesValue.data = null;
    state.getTeamPortfolioProfilesValue.error = null;
    state.getTeamPortfolioProfilesValue.loading = true;
  },
  GET_TEAM_PORTFOLIO_PROFILES_SUCCESS: (state, data) => {
    state.getTeamPortfolioProfilesValue.data = data;
    state.getTeamPortfolioProfilesValue.loading = false;
  },
  GET_TEAM_PORTFOLIO_PROFILES_INIT_ERROR: (state, error) => {
    state.getTeamPortfolioProfilesValue.error = error;
    state.getTeamPortfolioProfilesValue.loading = false;
  },
  UPDATE_TEAM_PORTFOLIO_PROFILE_INIT: (state) => {
    state.updateTeamPortfolioProfileValue.data = null;
    state.updateTeamPortfolioProfileValue.error = null;
    state.updateTeamPortfolioProfileValue.loading = true;
  },
  UPDATE_TEAM_PORTFOLIO_PROFILE_SUCCESS: (state, data) => {
    const profileToUpdate = state.getTeamPortfolioProfilesValue.data.findIndex(
      (item) =>
        item.profileId === data.profileId &&
        item.portfolioId === data.portfolioId
    );
    state.getTeamPortfolioProfilesValue.data[
      profileToUpdate
    ].portfolioUserRole = data.portfolioUserRole;
  },
  UPDATE_TEAM_PORTFOLIO_PROFILE_ERROR: (state, error) => {
    state.updateTeamPortfolioProfileValue.error = error;
    state.updateTeamPortfolioProfileValue.loading = false;
  },
  DELETE_TEAM_PORTFOLIO_PROFILE_INIT: (state) => {
    state.deleteTeamPortfolioProfileValue.data = null;
    state.deleteTeamPortfolioProfileValue.error = null;
    state.deleteTeamPortfolioProfileValue.loading = true;
  },
  DELETE_TEAM_PORTFOLIO_PROFILE_SUCCESS: (state, data) => {
    const profileToRemove = state.getTeamPortfolioProfilesValue.data.findIndex(
      (item) =>
        item.profileId === data.profileId &&
        item.portfolioId === data.portfolioId
    );
    state.getTeamPortfolioProfilesValue.data.splice(profileToRemove, 1);
  },
  DELETE_TEAM_PORTFOLIO_PROFILE_ERROR: (state, error) => {
    state.deleteTeamPortfolioProfileValue.error = error;
    state.deleteTeamPortfolioProfileValue.loading = false;
  },
  CREATE_TEAM_PORTFOLIO_PROFILE_INIT: (state) => {
    state.createTeamPortfolioProfileValue.data = null;
    state.createTeamPortfolioProfileValue.error = null;
    state.createTeamPortfolioProfileValue.loading = true;
  },
  CREATE_TEAM_PORTFOLIO_PROFILE_SUCCESS: (state, data) => {
    data.forEach((portfolioMember) => {
      state.getTeamPortfolioProfilesValue.data.push(portfolioMember);
    });
  },
  CREATE_TEAM_PORTFOLIO_PROFILE_ERROR: (state, error) => {
    state.createTeamPortfolioProfileValue.error = error;
    state.createTeamPortfolioProfileValue.loading = false;
  },
  PORTFOLIO_SETTINGS_INIT: (state) => {
    state.updatePortfolio = { data: null, loading: null, error: null };
    state.deletePortfolio = { data: null, loading: null, error: null };
  },
  UPDATE_PORTFOLIO_INIT: (state) => {
    state.updatePortfolio = { data: null, loading: true, error: null };
  },
  UPDATE_PORTFOLIO_SUCCESS: (state, data) => {
    state.updatePortfolio.data = data;
    state.getMyPortfolios.data.portfolios = findAndReplace(
      state.getMyPortfolios.data.portfolios,
      'id',
      data.id,
      data
    );
    state.updatePortfolio.loading = false;
  },
  UPDATE_PORTFOLIO_PRIVACY: (state, payload) => {
    if (
      !state.getPortfolioById ||
      !state.getPortfolioById.data ||
      state.getPortfolioById.data.portfolioId != payload.portfolioId
    ) {
      return;
    } else {
      state.getPortfolioById.data.isPublic = payload.isPublic;
      state.getPortfolioById.data.isListed = payload.isListed;
    }
  },
  UPDATE_PORTFOLIO_ERROR: (state, error) => {
    state.updatePortfolio.error = error;
    state.updatePortfolio.loading = false;
  },
  DELETE_PORTFOLIO_INIT: (state) => {
    state.deletePortfolio = { data: null, loading: null, error: null };
  },
  DELETE_PORTFOLIO_SUCCESS: (state, data) => {
    state.deletePortfolio.data = data;
    state.getMyPortfolios.data.portfolios = findAndDelete(
      state.getMyPortfolios.data.portfolios,
      'id',
      data
    );
  },
  DELETE_PORTFOLIO_ERROR: (state, error) => {
    state.deletePortfolio.error = error;
  },
  LIKE_PORTFOLIO_SUCCESS: (state, data) => {
    state.getPortfolioById.data.socials.isLiked = data.isLiked;
    state.getPortfolioById.data.socials.likeCount = data.likeCount;
  },
  LIKE_PORTFOLIO_ERROR: (state, error) => {
    state.deletePortfolio.error = error;
  },
  TOGGLE_COMPLETE_VIEW: (state, data) => {
    state.getPortfolioById.data.completeViewToggled = data;
  },

  // Favorite portfolio
  GET_MY_FAVORITE_PORTFOLIOS_INIT: (state) => {
    state.myFavoritePortfolios = { loading: true, data: null, error: null };
  },
  GET_MY_FAVORITE_PORTFOLIOS_SUCCESS: (state, data) => {
    state.myFavoritePortfolios.data = data.favoritePortfolios;
  },
  GET_MY_FAVORITE_PORTFOLIOS_LOADING: (state, isLoading) => {
    state.myFavoritePortfolios.loading = isLoading;
  },
  GET_FAVORITE_PORTFOLIO_INIT: (state) => {
    state.isFavoritePortfolio = false;
  },
  GET_FAVORITE_PORTFOLIO_SUCCESS: (state, data) => {
    state.isFavoritePortfolio = data.isFavorited;
  },

  FAVORITE_PORTFOLIO_INIT: (state) => {
    state.isFavoritePortfolio = false;
  },
  FAVORITE_PORTFOLIO_SUCCESS: (state, data) => {
    if (state.getPortfolioById.data && state.getPortfolioById.data.socials) {
      state.getPortfolioById.data.socials.favoriteCount =
        data.data.favoriteCount;
      state.isFavoritePortfolio = data.data.isFavorited;
      state.getPortfolioById.data.socials.isFavorited = data.data.isFavorited;
    }

    if (state.myFavoritePortfolios && state.myFavoritePortfolios.length > 0) {
      state.myFavoritePortfolios = findAndDelete(
        state.myFavoritePortfolios,
        'id',
        data.id
      );
    }
  },

  // Top Gainer Portfolios
  GET_PORTFOLIOS_TOP_GAINERS_INIT: (state) => {
    state.portfolioTopGainers = { data: null, loading: true, error: null };
  },
  GET_PORTFOLIOS_TOP_GAINERS_SUCCESS: (state, data) => {
    state.portfolioTopGainers.data = data;
    state.portfolioTopGainers.loading = false;
  },
  GET_PORTFOLIOS_TOP_GAINERS_ERROR: (state, er) => {
    state.portfolioTopGainers.data = er;
    state.portfolioTopGainers.loading = false;
  },
  LIKE_PORTFOLIO_TOP_GAINER_SUCCESS: (state, payload) => {
    const index = state.portfolioTopGainers.data.findIndex(
      (e) => e.id == payload.id
    );
    if (index !== -1) {
      state.portfolioTopGainers.data[index].socials.isLiked =
        payload.socials.isLiked;
      state.portfolioTopGainers.data[index].socials.likeCount =
        payload.socials.likeCount;
    }
  },
  FAVORITE_PORTFOLIO_TOP_GAINER_SUCCESS: (state, payload) => {
    const index = state.portfolioTopGainers.data.findIndex(
      (e) => e.id == payload.id
    );
    if (index !== -1) {
      state.portfolioTopGainers.data[index].socials.isFavorited =
        payload.socials.isFavorited;
      state.portfolioTopGainers.data[index].socials.favoriteCount =
        payload.socials.favoriteCount;
    }
  },
  GET_MONTHLY_PORTFOLIO_TOP_GAINERS_INIT: (state) => {
    state.monthlyPortfolioTopGainers = {
      data: null,
      loading: true,
      error: null,
    };
  },
  GET_MONTHLY_PORTFOLIO_TOP_GAINERS_SUCCESS: (state, data) => {
    state.monthlyPortfolioTopGainers.data = data;
    state.monthlyPortfolioTopGainers.loading = false;
  },
  GET_MONTHLY_PORTFOLIO_TOP_GAINERS_ERROR: (state, error) => {
    state.monthlyPortfolioTopGainers.error = error;
    state.monthlyPortfolioTopGainers.loading = false;
  },
  FAVORITE_PORTFOLIO_MONTHLY_TOP_GAINER_SUCCESS: (state, data) => {
    const index = state.monthlyPortfolioTopGainers.data.data.findIndex(
      (p) => p.id == data.id
    );
    if (index !== -1) {
      state.monthlyPortfolioTopGainers.data.data[index].socials.isFavorited =
        data.socials.isFavorited;
      state.monthlyPortfolioTopGainers.data.data[index].socials.favoriteCount =
        data.socials.favoriteCount;
    }
  },
  LIKE_PORTFOLIO_MONTHLY_TOP_GAINER_SUCCESS: (state, data) => {
    const index = state.monthlyPortfolioTopGainers.data.data.findIndex(
      (p) => p.id == data.id
    );
    if (index !== -1) {
      state.monthlyPortfolioTopGainers.data.data[index].socials.isLiked =
        data.socials.isLiked;
      state.monthlyPortfolioTopGainers.data.data[index].socials.likeCount =
        data.socials.likeCount;
    }
  },
  // Profile Portfolios
  GET_PROFILE_PORTFOLIOS_INIT: (state) => {
    state.profilePortfolios = { data: null, loading: true, error: null };
  },
  GET_PROFILE_PORTFOLIOS_SUCCESS: (state, data) => {
    state.profilePortfolios.data = data;
    state.profilePortfolios.loading = false;
  },
  GET_PROFILE_PORTFOLIOS_ERROR: (state, er) => {
    state.profilePortfolios.data = er;
    state.profilePortfolios.loading = false;
  },
  LIKE_PROFILE_PORTFOLIO_SUCCESS: (state, payload) => {
    const index = state.profilePortfolios.data.findIndex(
      (e) => e.id == payload.id
    );
    if (index !== -1) {
      state.profilePortfolios.data[index].socials.isLiked =
        payload.socials.isLiked;
      state.profilePortfolios.data[index].socials.likeCount =
        payload.socials.likeCount;
    }
  },
  LIKE_MY_PORTFOLIO_SUCCESS: (state, payload) => {
    const index = state.getMyPortfolios.data.portfolios.findIndex(
      (e) => e.id == payload.id
    );
    if (index !== -1) {
      state.getMyPortfolios.data.portfolios[index].socials.isLiked =
        payload.socials.isLiked;
      state.getMyPortfolios.data.portfolios[index].socials.likeCount =
        payload.socials.likeCount;
    }
  },

  FAVORITE_PROFILE_PORTFOLIO_SUCCESS: (state, payload) => {
    const index = state.profilePortfolios.data.findIndex(
      (e) => e.id == payload.id
    );
    if (index !== -1) {
      state.profilePortfolios.data[index].socials.isFavorited =
        payload.socials.isFavorited;
      state.profilePortfolios.data[index].socials.favoriteCount =
        payload.socials.favoriteCount;
    }
  },

  /// Portfolio Comments

  UPDATE_REPLY: (state, payload) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === payload.postId);

      if (index !== -1) {
        const res = [...state.replies];

        // Reply-related info are not sent back from the API
        const replies = res[index].replies;
        const showLoadMore = res[index].showLoadMore;

        res[index] = payload;
        res[index].replies = replies;
        res[index].showLoadMore = showLoadMore;
        state.replies = res;
      }
    }
  },
  UPDATE_NESTED_REPLY: (state, payload) => {
    if (state.replies) {
      const replyIndex = state.replies.findIndex(
        (e) => e.postId === payload.replyId
      );

      const nestedIndex = state.replies[replyIndex].replies.findIndex(
        (p) => p.postId === payload.nestedReply.postId
      );

      if (replyIndex !== -1 && nestedIndex !== -1) {
        const replies = [...state.replies];
        const nestedReplies = [...replies[replyIndex].replies];

        nestedReplies[nestedIndex] = payload.nestedReply;
        replies[replyIndex].replies = nestedReplies;

        state.replies = replies;
      }
    }
  },

  SET_REPLIES: (state, payload) => {
    state.replies = payload;
  },
  SET_NESTED_REPLIES: (state, payload) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === payload.postId);
      if (index !== -1) {
        const res = [...state.replies];
        res[index].replies = payload.replies;
        state.replies = res;
      }
    }
  },
  APPEND_REPLIES: (state, payload) => {
    state.replies = [...state.replies, ...payload];
  },
  APPEND_NEW_REPLY: (state, payload) => {
    if (state.postLimit) {
      if (!state.replies) {
        state.replies = [];
      }
      const newArray = state.replies.slice(0, 1);
      state.replies = [payload, ...newArray];
    } else {
      state.replies = [payload, ...state.replies];
    }
  },
  APPEND_NESTED_REPLIES: (state, payload) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === payload.postId);

      if (index !== -1) {
        const res = [...state.replies];

        res[index].replies = [...res[index].replies, ...payload.replies];
        state.replies = res;
      }
    }
  },
  APPEND_NEW_NESTED_REPLY: (state, payload) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === payload.postId);

      if (index !== -1) {
        const res = [...state.replies];
        if (!res[index].replies) res[index].replies = [];

        res[index].replies = [...res[index].replies, payload.post];
        state.replies = res;
      }
    }
  },
  SET_NESTED_REPLY_VIEW_MORE: (state, payload) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === payload.postId);
      if (index !== -1) {
        const res = [...state.replies];
        res[index].showLoadMore = payload.show;
        state.replies = res;
      }
    }
  },

  SET_REPLIES_LOADING: (state, isLoading) => {
    state.repliesLoading = isLoading;
  },
  SET_NEXT_REPLIES_LOADING: (state, isLoading) => {
    state.nextRepliesLoading = isLoading;
  },
  SET_NESTED_REPLIES_LOADING: (state, isLoading) => {
    state.nextRepliesLoading = isLoading;
  },

  SET_REPLIES_ERROR: (state, error) => {
    state.repliesError = error;
    showErrorToast(window.$nuxt.$t('error_post_page_replies'));
  },

  INIT_PAGE: (state) => {
    state.post = null;
    state.postLoading = false;
    state.postError = null;
    state.replies = null;
    state.repliesLoading = false;
    state.repliesError = null;
    state.newReplyCount = 0;
    state.isLoadFinished = false;
    state.postLimit = null;
  },

  INIT_REPLIES: (state) => {
    state.replies = null;
    state.repliesLoading = false;
    state.repliesError = null;
  },

  INCREMENT_REPLY_REPLYCOUNT: (state, postId) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === postId);

      if (index != -1) {
        state.replies[index].replyCount++;
      }
    }
  },
  INCREMENT_NESTED_REPLY_REPLYCOUNT: (state, payload) => {
    if (state.replies) {
      const replyIndex = state.replies.findIndex(
        (e) => e.postId === payload.replyId
      );

      if (replyIndex > -1 && state.replies[replyIndex].replies) {
        const nestedIndex = state.replies[replyIndex].replies.findIndex(
          (p) => p.postId === payload.nestedReplyId
        );

        if (nestedIndex !== -1) {
          const replies = [...state.replies];
          const nestedReplies = [...replies[replyIndex].replies];

          nestedReplies[nestedIndex].replyCount++;
          replies[replyIndex].replies = nestedReplies;

          state.replies = replies;
        }
      }
    }
  },
  INCREMENT_REPLY_REPOSTCOUNT: (state, postId) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === postId);

      if (index != -1) {
        state.replies[index].repostCount++;
      }
    }
  },
  INCREMENT_NESTED_REPLY_REPOSTCOUNT: (state, payload) => {
    if (state.replies) {
      const replyIndex = state.replies.findIndex(
        (e) => e.postId === payload.replyId
      );

      if (replyIndex > -1 && state.replies[replyIndex].replies) {
        const nestedIndex = state.replies[replyIndex].replies.findIndex(
          (p) => p.postId === payload.nestedReplyId
        );

        if (nestedIndex !== -1) {
          const replies = [...state.replies];
          const nestedReplies = [...replies[replyIndex].replies];

          nestedReplies[nestedIndex].repostCount++;
          replies[replyIndex].replies = nestedReplies;

          state.replies = replies;
        }
      }
    }
  },
  SET_IS_LOAD_FINISHED: (state, value) => {
    state.isLoadFinished = value;
  },
  INCREMENT_COMMENT_COUNT: (state) => {
    state.getPortfolioById.data.socials.commentCount++;
  },
  SET_INITIAL_INTERVAL_ITEMS_GAIN: (state) => {
    if (
      state.getPortfolioById.data &&
      state.getPortfolioById.data.items.length > 0
    ) {
      state.portfolioItemsGainByIntervals.data = [
        {
          // these keys should be kept up to date with filterIntervals keys in portfolio component pages\portfolios\_portfolioId.vue
          intervalKey: FILTER_INTERVAL_KEYS.ALL_TIME,
          values: state.getPortfolioById.data.items.map(
            ({ symbol, return: returnValue, returnPercent }) => ({
              code: symbol,
              return: returnValue,
              returnPercent: returnPercent,
            })
          ),
        },
      ];
    }
  },
  ITEMS_GAIN_FOR_INTERVAL_INIT: (state) => {
    state.portfolioItemsGainByIntervals.loading = true;
    state.portfolioItemsGainByIntervals.error = null;
  },
  HANDLE_ADD_ITEMS_GAIN_FOR_INTERVAL: (state, { data, intervalKey }) => {
    const intervalValues = state.portfolioItemsGainByIntervals.data.find(
      (interval) => {
        return interval.intervalKey == intervalKey;
      }
    );

    // updating portfolioItemsGainByIntervals array and gain/gain % fields for portfolio items
    if (!intervalValues) {
      state.portfolioItemsGainByIntervals.data.push({
        intervalKey: intervalKey,
        values: data,
      });

      data.forEach((itemValues) => {
        const portfolioItem = state.getPortfolioById.data.items.find((item) => {
          return item.symbol == itemValues.code;
        });

        // updating values for portfolio item
        if (portfolioItem) {
          portfolioItem.return = itemValues.return;
          portfolioItem.returnPercent = itemValues.returnPercent;
        }
      });
    }
  },
  UPDATE_PORTFOLIO_ITEMS_GAIN_VALUE(state, intervalKey) {
    const valueForInterval = state.portfolioItemsGainByIntervals.data.find(
      (values) => {
        return values.intervalKey == intervalKey;
      }
    );

    if (valueForInterval) {
      valueForInterval.values.forEach((itemValue) => {
        const portfolioItem = state.getPortfolioById.data.items.find((item) => {
          return item.symbol == itemValue.code;
        });

        if (portfolioItem) {
          portfolioItem.return = itemValue.return;
          portfolioItem.returnPercent = itemValue.returnPercent;
        }
      });
    }
  },
  SET_ITEMS_GAIN_FOR_INTERVAL_ERROR: (state, err) => {
    state.portfolioItemsGainByIntervals.error = err;
  },
  // Portfolio Search
  PORTFOLIO_SEARCH_INIT: (state) => {
    state.portfolioSearch.data = [];
    state.portfolioSearch.error = null;
    state.portfolioSearch.loading = true;
  },
  PORTFOLIO_SEARCH_SUCCESS: (state, data) => {
    state.portfolioSearch.data = data;
    state.portfolioSearch.loading = false;
  },
  PORTFOLIO_SEARCH_ERROR: (state, error) => {
    state.portfolioSearch.error = error;
    state.portfolioSearch.loading = false;
  },
  // Portfolio comparison tool
  PORTFOLIO_TO_COMPARE_INIT: (state) => {
    state.portfoliosToCompare.data = [];
  },
  ADD_PORTFOLIOS_TO_COMPARE: (state, data) => {
    if (state.portfoliosToCompare.data === null) {
      state.portfoliosToCompare.data = [];
    }
    data.forEach((portfolio) => {
      state.portfoliosToCompare.data.push(portfolio);
    });
  },
  REMOVE_PORTFOLIO_TO_COMPARE: (state, portfolioId) => {
    const portfolioIndex = state.portfoliosToCompare.data.findIndex(
      (portfolio) => portfolio.id === portfolioId
    );
    if (portfolioIndex !== -1) {
      state.portfoliosToCompare.data.splice(portfolioIndex, 1);
    }
  },
  REMOVE_ALL_PORTFOLIOS_FROM_COMPARISON: (state) => {
    state.portfoliosToCompare.data = [];
  },
  GET_PORTFOLIO_COMPARISON_GRAPH_INFO_SUCCESS: (state, data) => {
    state.getPortfolioComparisonGraphInfo = data;
  },
  GET_PORTFOLIO_COMPARISON_GRAPH_INFO_ERROR: (state, error) => {
    state.getPortfolioComparisonGraphInfo.error = error;
  },

  CREATE_PORTFOLIO_INIT: (state) => {
    state.createPortfolio = { data: null, loading: true, error: null };
  },
  CREATE_PORTFOLIO_SUCCESS: (state, data) => {
    state.createPortfolio.data = data;
    state.createPortfolio.loading = false;
    state.getMyPortfolios.data.portfolios.push(data);
  },
  CREATE_PORTFOLIO_ERROR: (state, error) => {
    state.createPortfolio.error = error;
    state.createPortfolio.loading = false;
  },
  SET_GET_MY_PORTFOLIOS_LOADING: (state, loading) => {
    state.loading = loading;
  },
  RESET_GET_PORTFOLIO_BY_ID: (state) => {
    state.getPortfolioById = { data: null, loading: null, error: null };
  },
  GET_PORTFOLIO_BY_ID_INIT: (state) => {
    state.getPortfolioById = { data: null, loading: true, error: null };
  },
  GET_PORTFOLIO_BY_ID_SUCCESS: (state, data) => {
    state.getPortfolioById = { data: data, loading: false, error: null };
  },
  GET_PORTFOLIO_BY_ID_ERROR: (state, error) => {
    state.getPortfolioById = { data: null, loading: false, error: error };
  },
  INCREMENT_PORTFOLIO_REPOST_COUNT: (state) => {
    state.getPortfolioById.data.socials.repostCount++;
  },
  ...MutationsCreator([
    {
      name: 'ORDER_PORTFOLIOS',
      state: 'orderPortfolios',
    },
    {
      name: 'GET_PORTFOLIO_GRAPH',
      state: 'getPortfolioGraph',
    },
    {
      name: 'GET_MY_PORTFOLIOS',
      state: 'getMyPortfolios',
    },
    {
      name: 'CREATE_OPERATION',
      state: 'createOperation',
    },
    {
      name: 'CREATE_CASH_OPERATION',
      state: 'createCashOperation',
    },
    {
      name: 'ADD_PORTFOLIO_ITEM',
      state: 'addPortfolioItem',
    },
    {
      name: 'REMOVE_PORTFOLIO_ITEM',
      state: 'removePortfolioItem',
    },
    {
      name: 'REMOVE_PORTFOLIO_CASH_ITEM',
      state: 'removePortfolioCashItem',
    },
  ]),
};
