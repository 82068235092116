import { render, staticRenderFns } from "./PollCreate.vue?vue&type=template&id=51a2e8e7&scoped=true"
import script from "./PollCreate.vue?vue&type=script&lang=js"
export * from "./PollCreate.vue?vue&type=script&lang=js"
import style0 from "./PollCreate.vue?vue&type=style&index=0&id=51a2e8e7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51a2e8e7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default})
