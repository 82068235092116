
import AwardIcon from '~/components/ui/icons/AwardIcon.vue';

/**
 * A small tag indicating a monetary change.
 *
 * The color is green if the gain was positive, and red otherwise.
 */
export default {
  name: 'PortfolioRankTag',
  components: { AwardIcon },
  props: {
    /**
     * The portfolio's ranking.
     */
    rank: {
      type: Number,
      required: true,
    },
    isPublic: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    styleClass() {
      let res = '';
      if (this.rank > 3) {
        res += 'bg-primary-1 text-primary';
      } else {
        res += ' text-neutral-1';
        switch (this.rank) {
          case 1:
            res += ' bg-yellow-500';
            break;
          case 2:
            res += ' bg-gray-400';
            break;
          case 3:
            res += ' bg-yellow-700';
            break;
        }
      }
      return res;
    },
    medalStroke() {
      if (this.rank <= 3) return 'neutral-1';
      else return 'primary';
    },
    rankStr() {
      if (this.rank > 999) return '+1k';
      else return this.rank;
    },
  },
};
